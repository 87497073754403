import React, { useEffect, useState, useRef } from "react"
import { useLocation } from "@reach/router"
import Link from "gatsby-plugin-transition-link"
import { motion, useCycle } from "framer-motion"
import Img from "gatsby-image"
import classnames from "classnames/bind"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import TransitionLink, { TransitionPortal } from "gatsby-plugin-transition-link"
import gsap from "gsap/gsap-core"
import TextPlugin from "gsap/TextPlugin"
import ScrollTrigger from "gsap/ScrollTrigger"
import ScrollToPlugin from "gsap/ScrollToPlugin"
import { TextAlignRight, X } from "phosphor-react"
import CustomEase from "../vendors/gsap/CustomEase.min"

// Data
import useProjectData from "../hook/projectpage-data"

// Style
import headerStyle from "../style/layouts/header.module.scss"

// Component
import { Container, WideContainer } from "./container"
import { ListTLink, newContent } from "./wrapper"
import IconsLogo from "../assets/logo/logo"

// -Link
const HeaderLink = props => pug`
  li(className=headerStyle.navItem)
    HeaderLinkItem(to=props.to)
      ${props.children}
`
/*
 * Page transition 寫在Header
 */

const pageTransitionExit = (node, { length }, direction) => {
  const directionTo = direction === "up" ? "-100%" : "100%"
  const directionFrom = direction === "up" ? "100%" : "-100%"
  const transitionCover = document.querySelector("#transitionCover")
  const exitContents = node

  const scrollTop =
    (document.scrollingElement && document.scrollingElement.scrollTop) ||
    document.body.scrollTop ||
    window.pageYOffset

  const seconds = length

  CustomEase.create("cubic", "0.77, 0, 0.18, 1")

  return gsap
    .timeline()
    .set(exitContents, {
      overflow: "hidden",
      scrollTo: scrollTop,
    })
    .set(transitionCover, {
      y: directionFrom,
    })
    .to(transitionCover, {
      y: "0%",
      ease: "cubic",
      duration: seconds / 2,
    })
    .set(exitContents, {
      opacity: 0,
    })
    .to(transitionCover, {
      y: directionTo,
      ease: "cubic",
      duration: seconds / 2,
    })
}

const pageTransitionCover = (node, { length }) => {
  const entryContents = node
  const seconds = length

  CustomEase.create("cubic", "0.77, 0, 0.18, 1")

  //* Remove transition. Otherwise header will perform like absolute instead of fixed
  setTimeout(function () {
    entryContents.classList.add(headerStyle.notransition)
  }, seconds * 1000 + 400)

  return gsap.timeline().from(entryContents, {
    opacity: 0,
    y: "+=50",
    duration: seconds,
    ease: "cubic",
  })
}

//* 因為pug無法正確寫出以下這一個component，所以拉出來用JS寫在這邊
const HeaderLinkItem = props => (
  <ListTLink
    to={props.to}
    exit={{
      length: 0.8,
      trigger: ({ node, exit }) => pageTransitionExit(node, exit, "up"),
    }}
    entry={{
      length: 0.8,
      // delay: 0.4,
      trigger: ({ node, entry }) => pageTransitionCover(node, entry),
    }}
    // trigger={async pages => {
    //   // wait until we have access to both pages
    //   const exit = await pages.exit
    //   const entry = await pages.entry
    //   // pass exit and entry element to function
    //   pageTransitionExit(exit, entry)
    //   await entry.visible
    //   pageTransitionCover(exit, entry)
    // }}
  >
    {props.children}
  </ListTLink>
)

const transition = {
  ease: [0.17, 0.84, 0.44, 1],
  duration: 0.8,
}
const itemVariants = {
  closed: { opacity: 0, y: 120, transition },
  open: { opacity: 1, y: 0, transition: { delay: 0.4, ...transition } },
}

export default function Header(props) {
  const transition = {
    ease: [0.77, 0, 0.18, 1],
    duration: 0.5,
  }
  const transitionEaseInOut = {
    ease: "easeInOut",
    duration: 0.5,
  }

  const headerBackdropVariants = {
    open: { y: 0, transition: { duration: 0.3, ...transition } },
    closed: { y: "100vh", transition: { duration: 0.3, ...transition } },
  }

  const headerLeftVariants = {
    open: { opacity: 1, color: "#fff", transition },
    closed: { opacity: 1, color: "#222", transition },
  }

  const headerFadeInVariants = {
    open: { opacity: 1, transition: { delay: 0.4, ...transitionEaseInOut } },
    closed: { opacity: 0, transition },
  }

  const headerFadeOutVariants = {
    initial: { opacity: 0 },
    closed: {
      opacity: 1,
      transition: { delay: 0.4, ...transitionEaseInOut },
    },
    open: { opacity: 0, transition: { delay: 0.4, ...transitionEaseInOut } },
  }

  const layoutVariants = {
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
    open: {
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.2,
      },
    },
  }

  // - Motion
  const [isOpen, toggleOpen] = useCycle(false, true)

  const toggleScroll = () => {
    useEffect(() => {
      initImage()
      if (typeof window === "undefined" || !window.document) {
        console.log(`windows error`)
        return
      }
      // -DisableScroll
      const targetElement = document.querySelector("#layoutWrapper")
      if (isOpen) {
        // disable when open
        disableBodyScroll(targetElement)
      } else {
        // enable when closed
        enableBodyScroll(targetElement)
        clearAllBodyScrollLocks()
      }
      // listen for modalShown state
    }, [isOpen])
  }
  toggleScroll()

  // const [scrollTop] = useState(0)

  // // - Small header size
  // const headerResizeScroll = () => {
  //   useEffect(() => {
  //     // When the user scrolls down 200px from the top of the windows, resize the header's size
  //     const scrollFunction = () => {
  //       if (
  //         document.body.scrollTop > 100 ||
  //         document.documentElement.scrollTop > 100
  //       ) {
  //         document
  //           .getElementById("header_background")
  //           .classList.add(headerStyle.small)
  //         document
  //           .getElementById("header_hamburger")
  //           .classList.add(headerStyle.small)
  //         document.getElementById("header_nav").classList.add(headerStyle.small)
  //       } else {
  //         document
  //           .getElementById("header_background")
  //           .classList.remove(headerStyle.small)
  //         document
  //           .getElementById("header_hamburger")
  //           .classList.remove(headerStyle.small)
  //         document
  //           .getElementById("header_nav")
  //           .classList.remove(headerStyle.small)
  //       }
  //     }
  //     window.addEventListener("scroll", scrollFunction)
  //     return () => {
  //       // When this function is over. Remove EventListener and extra class name
  //       window.removeEventListener("scroll", scrollFunction)
  //     }
  //   }, [scrollTop])
  // }
  // headerResizeScroll()

  // -MenuData
  const MenuData = [
    {
      text: "HOME",
      to: "/",
    },
    {
      text: "PROJECTS",
      to: "",
    },
    {
      text: "ABOUT",
      to: "/about",
    },
  ]

  // -MenuItemData
  const { edges } = useProjectData()
  const MenuItemData = edges

  // Init Menu Image Position
  function initImage() {
    const wrapperElement = document.querySelectorAll("#menuItemImg")
    wrapperElement.forEach(function (el) {
      const element = el.querySelector(".gatsby-image-wrapper")
      gsap
        .timeline()
        .set(el, {
          x: "50%",
          y: "120%",
          rotation: 180,
          opacity: 0,
        })
        .set(element, {
          x: "-50%",
          y: "-120%",
          rotation: -50,
          scale: 2,
          opacity: 0,
        })
    })
  }

  function showImage(e) {
    const wrapperElement = e.currentTarget.parentNode.querySelector(
      "#menuItemImg"
    )
    const targetElement = e.currentTarget.parentNode.querySelector(
      ".gatsby-image-wrapper"
    )

    gsap
      .timeline()
      .add("begin")
      .set(wrapperElement, {
        zIndex: 1601,
        x: "50%",
        y: "120%",
        rotation: 50,
        opacity: 1,
      })
      .set(targetElement, {
        x: "-50%",
        y: "-120%",
        rotation: -50,
        scale: 2,
        opacity: 1,
      })
      .to(
        wrapperElement,
        {
          ease: "expo.easeOut",
          x: "0%",
          y: "0%",
          rotation: 0,
          duration: 0.4,
        },
        "begin"
      )
      .to(
        targetElement,
        {
          ease: "expo.easeOut",
          x: "0%",
          y: "0%",
          rotation: 0,
          duration: 0.4,
        },
        "begin"
      )
      .to(
        targetElement,
        {
          scale: 1,
          duration: 0.6,
        },
        "begin"
      )
  }

  function hideImage(e) {
    const wrapperElement = e.currentTarget.parentNode.querySelector(
      "#menuItemImg"
    )
    const targetElement = e.currentTarget.parentNode.querySelector(
      ".gatsby-image-wrapper"
    )

    gsap
      .timeline()
      .add("begin")
      .to(
        wrapperElement,
        {
          ease: "expo.easeOut",
          y: "-120%",
          rotation: -5,
          duration: 0.4,
        },
        "begin"
      )
      .to(
        targetElement,
        {
          ease: "expo.easeOut",
          y: "120%",
          rotation: 5,
          scale: 1.2,
          duration: 0.4,
        },
        "begin"
      )
      .set(wrapperElement, {
        opacity: 0,
      })
      .set(targetElement, {
        opacity: 0,
      })
  }

  const Fadeout = (exit, node) => {
    // close backdrop
    toggleOpen(!isOpen)

    gsap.timeline().to(node, {
      opacity: 0,
    })
  }

  // Sticky Header    Show on Scroll down
  let lastScroll = 0

  useEffect(() => {
    // initial
    // gsap.fromTo(
    //   "header",
    //   {
    //     y: "-30%",
    //     opacity: 0,
    //   },
    //   { y: 0, opacity: 1, duration: 1, delay: 0.3 }
    // )

    const Stickyheader = () => {
      const currentScroll =
        window.pageYOffset || document.documentElement.scrollTop
      const header = document.querySelector("header")

      function showHeader() {
        header.classList.remove(headerStyle.hide)
        header.classList.add(headerStyle.show)
      }

      function hideHeader() {
        header.classList.remove(headerStyle.show)
        header.classList.add(headerStyle.hide)
      }

      if (currentScroll <= 0) {
        showHeader()
      } else if (currentScroll > lastScroll && lastScroll > 0) {
        hideHeader()
      } else if (currentScroll < lastScroll && lastScroll > 0) {
        showHeader()
      }

      lastScroll = currentScroll
    }
    window.addEventListener("scroll", Stickyheader)
    return () => {
      // When this function is over. Remove EventListener and extra class name
      window.removeEventListener("scroll", Stickyheader)
      lastScroll = 0
    }
  }, [])

  // -MenuItem
  const MenuItem = props => {
    const { pathname } = useLocation()

    return pug`
      motion.block.relative(className=classnames(headerStyle.menuSelect_menuItem,props.className) animName="Div" whileHover="hover" initial="closed" animate=isOpen?"open":"closed")
        div.inline-block.overflow-hidden.h-auto(className=headerStyle.menuSelect_menuItem_link onMouseEnter=showImage onMouseLeave=hideImage)
          motion(animName="Div" variants=itemVariants)
            // ListTLink用在同一頁會關閉不掉header backdrop，所以當連結為所在當頁時，使用普通連結，點擊關掉header backdrop
            if props.to == pathname
              Link(to=props.to onClick=() => {toggleOpen(!isOpen);})
                h1.block.txt-hasBorder-dark-white.uppercase=props.text
            else
              ListTLink(
                to=props.to
                exit={
                  trigger: ({ exit, node }) => Fadeout(exit,node),
                  length: 0.4,
                }
                entry={
                  appearAfter: 0.6,
                }
              )
                h1.block.txt-hasBorder-dark-white.uppercase=props.text
        div#menuItemImgWrapper.absolute(className=headerStyle.menuSelect_menuItem_img)
          div#menuItemImg(className=headerStyle.menuSelect_menuItem_img)
            Img(fluid=props.url alt=props.alt)

        //- Credits: Inspire by Mary Lou on codepen
        //- https://tympanus.net/codrops/2020/03/31/css-only-marquee-effect/
        //- div.absolute.top-0.left-0(className=headerStyle.menuSelect_menuItem_repeatWrapper)
        //-   motion.flex(className=headerStyle.menuSelect_menuItem_repeatWrapper_inner animName="Div" variants=ItemsRollingVariants)
        //-     //- Duplicate 5 times
        //-     each val in [1,2,3,4,5]
        //-       h1.txt-header-menu.inline-block.text-white.italic=props.text
    `
  }

  // ? register GSAP Plugin
  gsap.registerPlugin(TextPlugin, ScrollToPlugin, ScrollTrigger, CustomEase)

  return pug`
    header(className=classnames(headerStyle.header,props.darkmode?headerStyle.darkmode:""))
      // Header
      WideContainer.flex.justify-between.items-center.relative
        // Left
        div.z-logo(className=headerStyle.hLeft)
          HeaderLinkItem(to="/")
            motion(className=headerStyle.logoName animName="Div" initial="initial" animate=isOpen?"open":"closed" variants=headerFadeOutVariants)
              span.uppercase.txt-logo(className=props.darkmode?"text-white":"text-gray-100") CHIA CHING.
        // Right
        motion.z-fixed(className=headerStyle.hRight animName="Div" initial="initial" animate=isOpen?"open":"closed" variants=headerFadeOutVariants)
          // Hamburger
          div#header_hamburger.d-only-mobile.cursor-pointer(className=headerStyle.hamburger)
            div(className=headerStyle.menuWrapper onClick=() => {toggleOpen(!isOpen);})
              TextAlignRight(className=headerStyle.menuIcon size=26)
          // List
          ul.list-none#header_nav.d-only-pc(className=headerStyle.nav)
            each item,index in MenuData
              if (item.text=="PROJECTS")
                HeaderLink
                  span.txt-wide.link.uppercase.font-semibold(className=props.darkmode?"darkmode":"text-gray-100" onClick=() => {toggleOpen(!isOpen);})=item.text
              else
                HeaderLink(to=item.to)
                  span.txt-wide.link.uppercase.font-semibold(className=props.darkmode?"darkmode":"text-gray-100")=item.text
        div#header_background(className=headerStyle.header_background)

      // Header Backdrop
      TransitionPortal
        motion.headerFullPageMenu.bg-dark.inset-0.fixed.z-menu-backdrop(className=headerStyle.menuBackdropWrapper animName="Div" initial="closed" animate=isOpen?"open":"closed" variants=headerBackdropVariants)
          WideContainer
            div.flex.flex-none.justify-between.items-center.w-full.h-16
              Link(to="/")
                motion.uppercase.text-white.txt-logo(className=headerStyle.logoName animName="SPAN" initial="initial" animate=isOpen?"open":"closed" variants=headerFadeInVariants) CHIA CHING.
              motion(className=headerStyle.menuSelect_close animName="Div" variants=headerFadeInVariants)
                X(className=headerStyle.menuSelect_close_icon onClick=() => {toggleOpen(!isOpen);} size=26)
          Container
            motion.flex.justify-start.flex-col.w-full.mt-16(className=headerStyle.menuSelect_menuContent animName="Div" variants=layoutVariants)
              div.d-only-mobile.mb-12
                each item,index in MenuData
                  if (item.text!=="PROJECTS")
                    MenuItem(key=index text=item.text to=item.to)
              motion(className=headerStyle.menuSelect_menuContent_headline animName="Div" variants=headerFadeInVariants)
                div(className=headerStyle.menuSelect_menuContent_headline_deco)
                span.text-default.text-white Select Sandwich
              each item,index in MenuItemData
                if (index <= 3)
                  - const MenuItem = item.node.data
                  MenuItem(key=index text=MenuItem.project_name.text to="/projects/"+item.node.uid url=MenuItem.hero_image.fluid)
      // Page Transition Cover
      TransitionPortal
        div#transitionCover(className=headerStyle.transitionCover)
    `
}

export { HeaderLinkItem }
