import { useStaticQuery, graphql } from "gatsby"

const useProjectData = () => {
  const { ProjectData } = useStaticQuery(
    graphql`
      query {
        ProjectData: allPrismicProjectPage(
          sort: { fields: [data___order1___text], order: ASC }
        ) {
          edges {
            node {
              id
              uid
              data {
                about_content {
                  text
                  raw
                  html
                }
                about_title {
                  text
                  raw
                  html
                }
                hero_image {
                  fluid {
                    ...GatsbyPrismicImageFluid
                  }
                  alt
                }
                project_description {
                  text
                  raw
                  html
                }
                project_name {
                  text
                  raw
                  html
                }
                role {
                  text
                }
                timeline {
                  text
                }
                tool {
                  text
                }
                type {
                  text
                }
                order1 {
                  text
                }
              }
            }
          }
        }
      }
    `
  )
  return ProjectData
}

export default useProjectData
