import React, { useRef, useEffect } from "react"
import { Link } from "gatsby"
import classnames from "classnames/bind"
import { Envelope, DribbbleLogo, LinkedinLogo } from "phosphor-react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import CustomEase from "../vendors/gsap/CustomEase.min"
// Style
import footerStyle from "../style/layouts/footer.module.scss"
import buttonStyle from "../style/components/button.module.scss"
// Component
import { Container } from "./container"

const FooterLink = props => pug`
  li(className=footerStyle.navItem)
    Link(to=props.to)
      ${props.children}
`

export default function Footer() {
  const footerText = {
    subtitle: "Got an idea?",
    title: "LET’S CHAT! ",
    content: "For any collaborations, just reach me out via e-mail.",
    button1: "SAY HELLO!",
    button2: "RESUME",
  }
  const footerLinkMenu = [
    {
      link: "Dribbble",
      to: "",
    },
    {
      link: "Behance",
      to: "",
    },
    {
      link: "Linkedin",
      to: "",
    },
  ]

  // fadeInRef Array
  const fadeInRef = useRef([])
  fadeInRef.current = []

  const footerRef = useRef(null)

  const addToRefs = el => {
    if (el && !fadeInRef.current.includes(el)) {
      fadeInRef.current.push(el)
    }
  }

  useEffect(() => {
    gsap
      .timeline({
        duration: 0.2,
        scrollTrigger: {
          id: `footer-fadeIn`,
          trigger: footerRef.current,
          start: "top bottom",
          toggleActions: "play none none reverse",
          markers: false,
        },
      })
      .from(fadeInRef.current, {
        opacity: 0,
        y: 40,
      })
      .to(fadeInRef.current, {
        opacity: 1,
        y: 0,
        stagger: {
          // wrap advanced options in an object
          amount: 0.4,
          ease: "power2.out",
        },
        delay: 1,
      })
  }, [])

  return pug`
    footer(className=footerStyle.footer ref=footerRef)
      Container.flex.justify-between.items-center
        div.row.darkMode
          div.col-12.col-sm-12(className=footerStyle.fLeft)
            h4.txt-display2.font-normal.text-theme-200.mt-0(ref=addToRefs)=footerText.subtitle
            h1.txt-display1.txt-hasShadow-white-white.mt-2.uppercase.inline-block(ref=addToRefs)=footerText.title
            h1.txt-display1.inline-block.ml-6(ref=addToRefs) 🙌
            p.text-white(className=footerStyle.infoContent ref=addToRefs)=footerText.content
          div.col-12.pl-0(className=footerStyle.link)
            div.flex.items-center(className=classnames(footerStyle.actionBtn) ref=addToRefs)
              a(href="mailto:chiachingc3@gmail.com" className=classnames(buttonStyle.btn, buttonStyle.btn_lg, buttonStyle.btnShadow_white))
                span=footerText.button1
              //*Resume 使用 google drive 比較方便更動
              a(href="https://drive.google.com/file/d/1vJCNcrfMmeauokCqmG0U9RJ_gO8bESmO/view?usp=sharing" alt="resume" target="_blank" rel="noopener" className=classnames(buttonStyle.btn, buttonStyle.btn_lg, buttonStyle.btnShadow_white))
                span=footerText.button2
            div.flex.flex-col(className='md:items-end')
              div.flex.flex-row.mb-8(className=footerStyle.social ref=addToRefs)
                a(className=footerStyle.social_itemWrapper href="https://dribbble.com/ChiaChingChou" alt="dribbble" target="_blank" rel="noopener")
                  div(className=classnames(footerStyle.social_item,footerStyle.darkmode))
                    DribbbleLogo(size=24)
                a(className=footerStyle.social_itemWrapper href="https://www.linkedin.com/in/zoe-chou-c3/u" alt="linkedin" target="_blank" rel="noopener")
                  div(className=classnames(footerStyle.social_item,footerStyle.darkmode))
                    LinkedinLogo(size=24)
              span.text-theme-300.text-sm Copyright © 2020 ChiaChingChou. All rights reserved.


          // div.col-sm-3.d-only-pc(className=footerStyle.fRight)
            ul(className=footerStyle.nav)
              each item in footerLinkMenu
                FooterLink(to=item.to)
                  span.text-default.link.darkmode.uppercase=item.link
            ul(className=footerStyle.nav)
              each item in footerLinkMenu
                FooterLink(to="../index")
                  span.text-default.link.white.uppercase=item.link
    `
}
