import React from "react"
import classnames from "classnames/bind"
import { motion } from "framer-motion"
// Style
import ContainerStyle from "../style/layouts/container.module.scss"

const transition = {
  ease: "easeInOut",
  duration: 0.4,
}
const itemVariants = {
  initial: { opacity: 0, transition },
  in: { opacity: 1, transition },
}

// - MainContainer
const MainContainer = ({ children }) => pug`
    motion(className=ContainerStyle.mainContainer animName="Main" initial="initial" animate="in" exit="exit" variants=itemVariants)
      ${children}
`

// - Container
const Container = props => pug`
  div(className=classnames(ContainerStyle.container,props.className))
    ${props.children}
`

// - Wide Container
const WideContainer = props => pug`
  div(className=classnames(ContainerStyle.wideContainer,props.className))
    ${props.children}
`

// - Project Content Container
const ProjectContainer = props => pug`
  div.col-12.col-md-10.col-lg-8.mb-1.px-0(className=classnames(ContainerStyle.projectContainer,props.className))
    ${props.children}
`

export { MainContainer, Container, WideContainer, ProjectContainer }
